import { put, call, fork, debounce, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { first } from 'lodash';
import { extractPaymentDetailsData, formatCouponData } from '@containers/Payment/payment.helper';
import { selectCouponDetails } from '@containers/Payment/selectors';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { localStorageGetItem, localStorageSetItem, PAYMENT_DETAILS } from '@utils/localStorageHelpers';
import { GET_ORDER_DETAILS_QUERY, CREATE_TRANSACTION_MUTATION, VALIDATE_COUPON_MUTATION } from './queries';
import { fetchOrderDetailsRequest, fetchOrderDetailsSuccess, createTransactionRequest, createTransactionSuccess, validateCouponRequest, validateCouponSuccess, validateCouponFailed, } from './slice';
import fetchData from '@utils/fetchData';
export function* fetchOrderDetailsWorker(action) {
    const { orderId, callback } = action.payload;
    try {
        const storedPaymentDetails = (yield call(localStorageGetItem, PAYMENT_DETAILS));
        const oId = orderId || Number(storedPaymentDetails);
        const paymentOrderResponseTest = (yield call(fetchData, {
            queryString: GET_ORDER_DETAILS_QUERY,
            queryVariables: { order_id: oId },
            queryKey: 'canx_get_order_details',
            forceRefresh: true,
        }));
        const orderDetails = paymentOrderResponseTest === null || paymentOrderResponseTest === void 0 ? void 0 : paymentOrderResponseTest[0];
        if (orderDetails) {
            yield call(localStorageSetItem, PAYMENT_DETAILS, String(oId));
            const formattedData = (yield call(extractPaymentDetailsData, orderDetails));
            const data = first(formattedData);
            yield put(fetchOrderDetailsSuccess(data));
            if (callback === null || callback === void 0 ? void 0 : callback.onSuccess) {
                yield call(callback.onSuccess, formattedData);
            }
        }
        else {
            throw new Error('An error occurred while fetching order details.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Fetch Order Details Worker', error: error, skipToast: true });
        if (callback === null || callback === void 0 ? void 0 : callback.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* createTransactionWorker(action) {
    var _a;
    const { callback } = action.payload;
    try {
        const couponDetails = (yield select(selectCouponDetails));
        const storedPaymentDetails = (yield call(localStorageGetItem, PAYMENT_DETAILS));
        const orderId = storedPaymentDetails || Number(storedPaymentDetails);
        const createTransactionResponse = (yield call(postData, {
            queryString: CREATE_TRANSACTION_MUTATION,
            payload: { orderId, coupon_code: couponDetails.couponCode },
            spreadPayload: true,
        }));
        const transactionData = (_a = createTransactionResponse === null || createTransactionResponse === void 0 ? void 0 : createTransactionResponse.data) === null || _a === void 0 ? void 0 : _a.pay_create_transaction;
        if (transactionData) {
            yield put(createTransactionSuccess());
            yield call(localStorageSetItem, PAYMENT_DETAILS, String(transactionData.order_id));
            if (callback === null || callback === void 0 ? void 0 : callback.onSuccess) {
                yield call(callback.onSuccess, transactionData);
            }
        }
        else {
            throw new Error('Transaction creation failed');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Create Transaction Worker', error: error, skipToast: true });
        if (callback === null || callback === void 0 ? void 0 : callback.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* validateCouponWorker(action) {
    var _a;
    const { order_id, coupon_code, callback } = action.payload;
    try {
        const response = (yield call(postData, {
            queryString: VALIDATE_COUPON_MUTATION,
            payload: { orderId: order_id, couponCode: coupon_code },
            spreadPayload: true,
        }));
        const validateCouponResponse = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.pay_validate_coupon;
        if (validateCouponResponse) {
            const formatCouponRes = formatCouponData(validateCouponResponse);
            yield put(validateCouponSuccess(formatCouponRes));
            if (callback === null || callback === void 0 ? void 0 : callback.onSuccess) {
                yield call(callback.onSuccess, validateCouponResponse);
            }
        }
        else {
            throw new Error('Failed to validate coupon.');
        }
    }
    catch (error) {
        yield call(catchError, { title: 'Validate Coupon Worker', error: error, skipToast: true });
        yield put(validateCouponFailed());
        if (callback === null || callback === void 0 ? void 0 : callback.onError) {
            yield call(callback.onError, error);
        }
    }
}
export function* fetchOrderDetailsSagaWatcher() {
    yield debounce(1000, fetchOrderDetailsRequest.type, fetchOrderDetailsWorker);
}
export function* createTransactionSagaWatcher() {
    yield takeEvery(createTransactionRequest.type, createTransactionWorker);
}
export function* validateCouponSagaWatcher() {
    yield takeLatest(validateCouponRequest.type, validateCouponWorker);
}
export function* fetchOrderDetailsRootSaga() {
    yield fork(fetchOrderDetailsSagaWatcher);
    yield fork(createTransactionSagaWatcher);
    yield fork(validateCouponSagaWatcher);
}
