var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { filter, find, first, get } from 'lodash';
import { produce } from 'immer';
import { catchError } from '@utils/sentry';
export const getConstraints = (videoSourceDevice, audioSourceDevice) => ({
    video: {
        width: 320,
        height: 240,
        aspectRatio: 1.3333333333333333,
        frameRate: { ideal: 30 }, // Frame rate
        facingMode: 'user', // Use 'environment' for back camera
        deviceId: videoSourceDevice ? { exact: get(videoSourceDevice, 'deviceId') } : undefined,
    },
    audio: {
        echoCancellation: true, // Enable echo cancellation
        noiseSuppression: true, // Enable noise suppression
        autoGainControl: true, // Enable auto gain control
        deviceId: audioSourceDevice ? { exact: get(audioSourceDevice, 'deviceId') } : undefined,
    },
});
export const getStream = (constraints) => __awaiter(void 0, void 0, void 0, function* () {
    return yield navigator.mediaDevices.getUserMedia(constraints);
});
export const getStreamTracks = (stream) => {
    const videoTrack = first(stream.getVideoTracks());
    const audioTrack = first(stream.getAudioTracks());
    return { videoTrack, audioTrack };
};
export const getDevicePermission = () => __awaiter(void 0, void 0, void 0, function* () {
    const stream = yield navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    const devices = yield navigator.mediaDevices.enumerateDevices();
    if (devices.length) {
        const videoDevices = filter(devices, (device) => device.kind === 'videoinput');
        const audioDevices = filter(devices, (device) => device.kind === 'audioinput');
        const { videoTrack, audioTrack } = getStreamTracks(stream);
        const selectedVideoDevice = find(videoDevices, (device) => { var _a; return device.deviceId === ((_a = videoTrack === null || videoTrack === void 0 ? void 0 : videoTrack.getSettings()) === null || _a === void 0 ? void 0 : _a.deviceId); });
        const selectedAudioDevice = find(audioDevices, (device) => { var _a; return device.deviceId === ((_a = audioTrack === null || audioTrack === void 0 ? void 0 : audioTrack.getSettings()) === null || _a === void 0 ? void 0 : _a.deviceId); });
        stopStream(stream);
        return { videoDevices, audioDevices, selectedVideoDevice, selectedAudioDevice };
    }
    else {
        return Promise.reject(new Error('getDevicePermission: No media devices found.'));
    }
});
export const stopStream = (stream) => {
    if (stream && stream.active) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
    }
};
export const getSupportedMimeType = () => {
    let mimeTypeSupported;
    if (typeof window.MediaRecorder == 'function' && typeof window.MediaRecorder.isTypeSupported == 'function') {
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
            mimeTypeSupported = 'video/webm;codecs=vp8,opus';
        }
        else if (MediaRecorder.isTypeSupported('video/webm')) {
            mimeTypeSupported = 'video/webm';
        }
        else if (MediaRecorder.isTypeSupported('video/mp4')) {
            mimeTypeSupported = 'video/mp4';
        }
        return mimeTypeSupported;
    }
};
export const initialMediaState = {
    availableVideoInputDevices: [],
    availableAudioInputDevices: [],
    selectedVideoInputDevice: undefined,
    selectedAudioInputDevice: undefined,
    videoTrack: undefined,
    audioTrack: undefined,
    stream: undefined,
};
export const mediaStateReducer = (mediaState, action) => {
    return produce(mediaState, (draftState) => {
        switch (action.type) {
            case 'SET_MEDIA_CONTENTS':
                draftState.availableVideoInputDevices = action.payload.videoDevices;
                draftState.availableAudioInputDevices = action.payload.audioDevices;
                draftState.selectedVideoInputDevice = action.payload.selectedVideoDevice;
                draftState.selectedAudioInputDevice = action.payload.selectedAudioDevice;
                break;
            case 'SET_SELECTED_AUDIO_INPUT_DEVICE':
                draftState.selectedAudioInputDevice = action.payload;
                break;
            case 'SET_SELECTED_VIDEO_INPUT_DEVICE':
                draftState.selectedVideoInputDevice = action.payload;
                break;
            case 'SET_STREAM':
                draftState.stream = action.payload.stream;
                draftState.videoTrack = action.payload.videoTrack;
                draftState.audioTrack = action.payload.audioTrack;
                break;
            default:
                return mediaState;
        }
    });
};
export const getIdRecordingSupported = () => {
    if (!window.MediaRecorder) {
        // Todo: Show a message to the user for browsers not supporting MediaRecorder
        catchError({
            title: 'Recording is not supported',
            error: new Error('Recording is not supported in your browser. Please try using a modern browser like Chrome or Firefox.'),
            skipToast: true,
        });
        return false;
    }
    if (!getSupportedMimeType()) {
        // Todo: Show a message to the user for mimeTypes not supported
        catchError({
            title: 'No suitable MIME type',
            error: new Error('No suitable MIME type found for recording.'),
        });
        return false;
    }
    return true;
};
