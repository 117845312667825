export const mixpanelEvents = {
    CANDIDATE_TIMER_HIDE: 'candidate_timer_hide',
    CANDIDATE_TIMER_UNHIDE: 'candidate_timer_unhide',
    CANDIDATE_INSTRUCTION_PAGE_LANDED: 'candidate_instruction_page_landed',
    CANDIDATE_LOGIN_SOCIAL_MEDIA_INITIATED: 'candidate_login_social_media_initiated',
    CANDIDATE_LOGIN_SOCIAL_MEDIA_SUCCESS: 'candidate_login_social_media_success',
    CANDIDATE_ASSESSMENT_SUBMITTED: 'candidate_assessment_submitted',
    CANDIDATE_ANSWER_SELECTED: 'candidate_answer_selected',
    CANDIDATE_NEXT_QUESTION_CLICKED: 'candidate_next_question_clicked',
    CANDIDATE_LOGIN_OTP_REQUESTED: 'candidate_login_otp_requested',
    CANDIDATE_LOGIN_SOCIAL_MEDIA_LOGIN_FAILED: 'candidate_login_social_media_login_failed',
    CANDIDATE_LOGIN_OTP_FILLED_LOGIN_SUCCESS: 'candidate_login_otp_filled_login_success',
    CANDIDATE_SCORE_PAGE_NEXT_CLICKED: 'candidate_score_page_next_clicked',
    TASK_TAKER_FEEDBACK_PAGE: 'task_taker_feedback_page',
    CANDIDATE_SCORE_PAGE_LOAD: 'candidate_score_page_load',
    CANDIDATE_AUTHENTICATED: 'candidate_autheticated'
};
