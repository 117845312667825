var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { findIndex, first, sortBy, filter, flatMap, get, find, map, every } from 'lodash';
import { convertTimeToSeconds } from '@utils/helpers';
import { getCurrentDate } from '@utils/dateHelpers';
export const processStartAssessmentResponse = (data) => {
    const { catalog_assessment } = data, startAssessmentResponse = __rest(data, ["catalog_assessment"]);
    const assessment = catalog_assessment === null || catalog_assessment === void 0 ? void 0 : catalog_assessment.assessment;
    const assessmentSections = assessment === null || assessment === void 0 ? void 0 : assessment.assessment_sections;
    const sortedAssessmentSections = sortBy(assessmentSections, 'sort_order');
    const firstSection = first(sortedAssessmentSections);
    return {
        assessment,
        assessmentSections,
        assessmentSection: firstSection,
        startAssessmentResponse,
    };
};
export const processStartSectionResponse = (data, appState) => {
    var _a;
    const { assessment_section, questions } = data, startSectionResponse = __rest(data, ["assessment_section", "questions"]);
    let nextQuestionId;
    const appStateData = Number(appState === null || appState === void 0 ? void 0 : appState.section_id) === Number(startSectionResponse.id) ? appState === null || appState === void 0 ? void 0 : appState.state[0] : {};
    if (!(appStateData === null || appStateData === void 0 ? void 0 : appStateData.nextQuestionId) && !(appStateData === null || appStateData === void 0 ? void 0 : appStateData.currentQuestionId)) {
        nextQuestionId = first(questions);
    }
    else if (appStateData === null || appStateData === void 0 ? void 0 : appStateData.currentQuestionId) {
        const currentQuestionIdAnswered = find(appStateData === null || appStateData === void 0 ? void 0 : appStateData.answers, { question_id: appStateData === null || appStateData === void 0 ? void 0 : appStateData.currentQuestionId });
        nextQuestionId = currentQuestionIdAnswered ? appStateData === null || appStateData === void 0 ? void 0 : appStateData.nextQuestionId : appStateData === null || appStateData === void 0 ? void 0 : appStateData.currentQuestionId;
    }
    return {
        assessmentSection: assessment_section,
        next_question_id: nextQuestionId,
        startSectionResponse,
        questionsIds: questions,
        parsedMaxSectionTime: (_a = appStateData === null || appStateData === void 0 ? void 0 : appStateData.remaining_time) !== null && _a !== void 0 ? _a : convertTimeToSeconds(assessment_section === null || assessment_section === void 0 ? void 0 : assessment_section.max_section_time),
        answers: (appStateData === null || appStateData === void 0 ? void 0 : appStateData.answers) || [],
    };
};
export const getNextQuestionId = (questionsIds, currentQuestionId) => {
    const currentQuestionIndex = findIndex(questionsIds || [], (question) => question === currentQuestionId);
    return (questionsIds === null || questionsIds === void 0 ? void 0 : questionsIds.length) ? questionsIds[currentQuestionIndex + 1] : null;
};
export const getOverallSections = (data) => {
    return filter(data, (item) => { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.section_configuration) === null || _a === void 0 ? void 0 : _a.type) === 'OVERALL'; });
};
export const getOverallNumericScore = (data) => {
    if (!data)
        return null;
    const overallSections = getOverallSections(data);
    const overallAttributes = flatMap(overallSections, (section) => filter(section.measurement_attributes, (attr) => get(attr, 'section_configuration_attribute.type') === 'OVERALL' &&
        get(attr, 'section_configuration_attribute.attribute.attribute_type') === 'NUMERIC'));
    return overallAttributes.length > 0 ? overallAttributes[0] : null;
};
export const getSkillScores = (data) => {
    if (!data)
        return null;
    const overallSections = getOverallSections(data);
    const skillScores = flatMap(overallSections, (section) => filter(section.measurement_attributes, (attr) => get(attr, 'section_configuration_attribute.type') === 'SUB_VALUE' &&
        get(attr, 'section_configuration_attribute.attribute.attribute_type') === 'NUMERIC'));
    return skillScores.length > 0 ? skillScores : null;
};
export const getResult = (data) => {
    if (!data)
        return null;
    const overallSections = getOverallSections(data);
    const result = flatMap(overallSections, (section) => filter(section.measurement_attributes, (attr) => get(attr, 'section_configuration_attribute.type') === 'OVERALL' &&
        get(attr, 'section_configuration_attribute.attribute.attribute_type') === 'ORDINAL'));
    return result.length > 0 ? get(result[0], 'ordinal.label', null) : null;
};
export const parseSubmittedAnswer = (payload, currentQuestion, startSectionResponse) => {
    var _a, _b;
    let result;
    const selectedChoices = (_a = payload === null || payload === void 0 ? void 0 : payload.data) === null || _a === void 0 ? void 0 : _a.choice;
    const questionChoices = currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.question_choices;
    const formattedChoices = map(selectedChoices, (choice) => {
        const questionChoice = find(questionChoices, { id: choice });
        return {
            id: choice,
            is_correct: questionChoice === null || questionChoice === void 0 ? void 0 : questionChoice.is_correct,
        };
    });
    if (questionChoices) {
        result = {
            data: {
                id: 'has_to_save',
                section_id: startSectionResponse.id,
                question_id: currentQuestion.id,
                choice: selectedChoices,
                // time_to_response?: string;
                answered_at: (_b = getCurrentDate()) === null || _b === void 0 ? void 0 : _b.toUTC().toISO(),
                is_correct_choice: every(formattedChoices, (choice) => typeof choice.is_correct === 'boolean')
                    ? every(formattedChoices, ['is_correct', true])
                    : null,
            },
            success: true,
        };
    }
    else {
        result = {
            error_message: 'Error on save answer in state',
            data: null,
            success: false,
        };
    }
    return result;
};
export const parseBulkSavedAnswer = (response, allAnswers) => {
    const saveAnswerResponse = response.data.ae_save_answer;
    const errorResponse = filter(saveAnswerResponse, { success: false });
    const successResponse = filter(saveAnswerResponse, { success: true });
    const formattedAnswers = allAnswers.map((answer) => {
        const foundItem = successResponse.find((item) => item.data.question_id === answer.question_id);
        return foundItem ? foundItem.data : answer;
    });
    return {
        formattedAnswers: formattedAnswers || [],
        failedToSaveAnswer: errorResponse || [],
        successToSaveAnswer: successResponse || [],
    };
};
export const getCurrentQuestionIndex = (questionIds, currentQuestion) => {
    const index = findIndex(questionIds, (id) => id === (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.id));
    return index + 1;
};
