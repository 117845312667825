import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    request: {},
    data: {},
};
export const schedulingSlice = createSlice({
    name: 'scheduling',
    initialState,
    reducers: {
        fetchMeeting(_state, _action) { },
        storeMeeting(state, action) {
            state.data = action.payload;
        },
    },
});
export const { fetchMeeting, storeMeeting } = schedulingSlice.actions;
export default schedulingSlice.reducer;
